/*-------------------------------------------------------------------
    파일정의 : Settings
    생성일자 : 2020-06-30 mangojang
-------------------------------------------------------------------*/
@import 'reset_custom';

/*-------------------------------------------------------------------
    Globals
-------------------------------------------------------------------*/
@padding: 28px;
@mainSectionBottom : 44px;
@headerH: 337px;
@listGap01: 3.3%;

.d_only{
    display: none !important;
}

.dt_only{
    display: block !important;
}

.m_only{
    display: none !important;
}

.wrap01{
    padding: 0 @padding;
    .afters;
}
.wrap02{
    .wrap01;
}
.wrap04{
    padding: 0 13%;
    margin: 0 auto;
    .afters;
}
.wrap05{
    padding: 0 15.6%;
    margin: 0 auto;
    .afters;
}
.wrap06{
    .wrap01;
    padding: 0;
}
.navigationBtnCommon{
    width: 40px;
    height: 40px;
    border:@border-pointColor;
    .bgo;
    background-color: #fff;
    background-size: 8px;
    cursor: pointer;
    &.swiper-button-disabled,&.disabled{
        border: @border01;
    }
}
.navigationPrev{
    background-image: url(/asset/images/btn-arrow-onlyline-prev.png);
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-prev-dis.png);
    }
}
.navigationNext{
    background-image: url(/asset/images/btn-arrow-onlyline-next.png);
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-next-dis.png);
    }
}
.navigationPrevFirst{
    background-image: url(/asset/images/icn-w-pagination-line-arrow-first.png);
    background-size: 13px;
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-first-dis.png);
    }
}
.navigationNextLast{
    background-image: url(/asset/images/icn-w-pagination-line-arrow-last.png);
    background-size: 13px;
    &:focus,&.active{
        outline: none;
    }
    &.swiper-button-disabled,&.disabled{
        background-image: url(/asset/images/icn-w-pagination-line-arrow-last-dis.png);
    }
}

@pvts-fontsize: 14px;
@pvts-lineheight: 1.71;
@pvts-tbordercolor: @grey01;
@pvts-tbgcolor: @bgColor01;
@pvts-tpadding: 22px 10px;
.post_view_txt_section{
    p,span,strong,b,u,a,i,div{
        line-height: @pvts-lineheight;
        font-weight: 500;
        font-size: @pvts-fontsize !important;
    }
    strong,b{
        font-weight: bold;
    }
    a{
        text-decoration: underline;
    }
    img,iframe,video{
        max-width: 100%;
    }
    table{
        width: 100%;
        margin: 10px 0;
        table-layout: fixed;
        th,td{
            font-size: @pvts-fontsize;
            border: 1px solid @pvts-tbordercolor;
            padding: @pvts-tpadding;
            word-break: keep-all;
            font-weight: 500;
            text-align: center;
        }
        th{
            font-weight: bold;
            background-color: @pvts-tbgcolor;
            p,span,strong,b,u,a,i,div{
                font-size: inherit;
                font-weight: inherit;
                word-break: keep-all;
                text-align: inherit;
            }
            strong,b{
                font-weight: bold;
            }
        } 
        td{
            line-height: @pvts-lineheight;
            p,span,strong,b,u,a,i,div{
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                word-break: keep-all;
                text-align: inherit;
            }
            strong,b{
                font-weight: bold;
            }
        }
    }
    //editor class
    .note-float-right,.note-float-left{
        margin: 10px;
    }
}

.badge_box{
    .badge{
        &.play{ //공연
            background-color: @play;
        }
        &.exhibition{ //전시
            background-color: @display;
        }
        &.event{ //행사
             background-color: @event;
        }
        &.education{ //교육
            background: @education;
        }
        &.news{ //소식
            background: @news;
        }
        &.important{ //중요
            background-color: @important;
        }
        &.ongoing{ //ongoing
            background-color: @important;
            .eFont;
        }
        &.progress{ //진행
            background-color: @progress;
        }
        &.expected{ //예정
            background-color: @expected;
        }
        &.end{ //종료
            background-color: @end;
        } 
    }
}


/*-------------------------------------------------------------------
    Plugin
-------------------------------------------------------------------*/
.swiper-container{
    padding-bottom: 70px;
    .swiper-pagination{
        width: auto;
        left: 50%;
        transform: translateX(-50%);
        .swiper-pagination-bullet{
            width: 14px;
            height: 14px;
            border: 1px solid white;
            background-color: white;
            opacity: 1;
            position: relative;
            &:after{
                content: '';
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: @pointColor;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
            &.swiper-pagination-bullet-active{
                border: @border-pointColor;
            }
        }
    }
}

.sld_navigation{
    width: 240px;
    .afters;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 1;
    .btn_sld_prev,.btn_sld_next{
        .navigationBtnCommon;
    }
    .btn_sld_prev{
        float: left;
        .navigationPrev
    }
    .btn_sld_next{
        float: right;
        .navigationNext
    }
}
.sld_next,.sld_prev{
    .navigationBtnCommon
}
.sld_next{
    .navigationNext;
}
.sld_prev{
    .navigationPrev;
}
.ui-datepicker{
    width: 18em;
    border-radius: 0;
    border: 1px solid #e8e8e8;
    padding: 0;
    .ui-widget-header{
        border: none;
        border-radius: 0;
        background-color: white;
        padding: 0.813em 0.75em;
        background-color: @pointColor;
        .ui-datepicker-title{
            span{
                .eFont;
                font-size: 1.5em;
                color: white;
            }
        }
        .ui-datepicker-next,.ui-datepicker-prev{
            border: @border-pointColor;
            border-radius: 0;
            .bgo;
            background-size: 6px;
            background-color: white;
            width: 2.5em;
            height: 2.5em;
            top: 0.5em;
            span{
                .bld;
            }
        }
        .ui-datepicker-next{
            .navigationNext;
            right: 0.75em;
        }
        .ui-datepicker-prev{
            .navigationPrev;
            left: 0.75em;
            
        }
    }
    .ui-datepicker-calendar{
        width: calc(100% - 0.625em*2);
        margin: 0.625em;
        thead{
            th{
                background-color: @grey03;
                padding: .4em .3em;
                span{
                    display: block;
                    font-size: 0.813em;
                    .eFont;
                    color: @pointColor;
                    text-transform: uppercase;
                    line-height: 1;
                }
                span[title="Sunday"]{
                    color: @red01;
                }
                span[title="Saturday"]{
                    color: @blue01;
                }
            }
        }
        tbody{
            td{
                .ui-state-default{
                    width: 1.75em;
                    height: 1.75em;
                    border: none;
                    background-color: #fff;
                    .eFont;
                    text-align: center;
                    padding: 0.375em 0;
                    margin: 0 auto;
                    line-height: 1;
                    &.ui-state-highlight{
                        background-color: inherit;
                        color: inherit;
                    }
                    &.ui-state-active{
                        background-color: @pointColor;
                        color: white !important;
                        border-radius: 50%;
                    }
                }
                &.ui-datepicker-week-end{
                    &:first-child{
                        .ui-state-default{
                            color: @red01;
                        }
                    }
                    &:last-child{
                        .ui-state-default{
                            color: @blue01;
                        }
                    }
                } 
            }
        }
    }
}

.mfp-bottom-bar{
    .bld;
}
.mfp-arrow{
    &::after{
        border: none;
        width: 36px;
        height: 36px;
        .bgo;
        background-size: 20px;
    }
}
.mfp-arrow-right{
    &::after{
        background-image: url(/asset/images/btn-w-pagination-line-arrow-white-next-act.png);
    }
}

.mfp-arrow-left{
    &::after{
        background-image: url(/asset/images/btn-w-pagination-line-arrow-white-prev-act.png);
    }
}
.mfp-close{
    &.btn_close{
        width: 20px;
        height: 20px;
        padding-right: 0;
        right: 0;
        top: 10px;
        background: url(/asset/images/icn-menu-close-white.png) no-repeat center/18px;
    }
}
/*-------------------------------------------------------------------
    Components
-------------------------------------------------------------------*/
/*inputs*/
input[type="text"]{
    &::placeholder{
        font-weight: inherit;
        font-size: inherit;
        color: inherit;
    }
}

.select_box01{
    width: 100%;
    height: 40px;
    padding: 0 26px;
    background: @grey03 url(/asset/images/object-arrow-down.png) no-repeat right 22px center/14px;
    .eFont;
    color: @pointColor;
    option{
        background-color: white;
    }
}
.select_box02{
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: 300;
    color: @pointColor;
    background:#fff url(/asset/images/object-arrow-down.png) no-repeat right 20px center/14px;
    padding: 0 15px;
}
.check_box{
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]+label span {
        .inline-block;
        width: 14px;
        height: 14px;
        margin: -2px 0px 0 0;
        vertical-align: -3px;
        border-radius: 50%;
        background-color: #fff;
        border: @border-pointColor;
        cursor: pointer;
    }
    input[type="checkbox"]:checked+label span {
        background: @pointColor url(/asset/images/icn-check-checked-14-px.png) no-repeat center;
    }
    >span {
        font-size: 10px;
        font-weight: bold;
        color: @pointColor;
    }
}
.check_box02{
    font-size: 0;
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]+label span {
        .inline-block;
        width: 18px;
        height: 18px;
        margin: -2px 5px 0 0;
        vertical-align: -4px;
        border-radius: 50%;
        background:#fff url(/asset/images/icn-check-unchecked-18-px.png) no-repeat center/10px;
        border: 1px solid #d8d8d8;
        cursor: pointer;
    }
    input[type="checkbox"]:checked+label span {
        border: @border-pointColor;
        background-image: url(/asset/images/icn-check-checked-14-px.png);
        background-color: @pointColor;
    }
    >span {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: -.3px;
        color: @textColor01;
        
    }
    &.big{
        input[type="checkbox"]+label span {
            width: 24px;
            height: 24px;
            background-size: 13px;
            vertical-align: -5px;
            margin: -2px 5px 0 0;
        }
        >span{
            font-size: 16px;
            font-weight: bold;
            color: @pointColor;
            .cmt{
                font-size: 14px;
                font-weight: inherit;
                color: inherit;
            }
        }
    }
}
.input_box01{
    border: @border03;
    background-color: @bgColor01;
    height: 40px;
    input,select{
        width: 100%;
        height: 100%;
        padding: 0 15px;
        background-color: @bgColor01;
        font-size: 15px;
        font-weight: 300;
        color: @pointColor;
        &::placeholder{
            color: @grey02;
        }
    }
    select{
        padding: 0 44px 0 15px;
        background: @bgColor01 url(/asset/images/object-arrow-down.png) no-repeat right 15px center/14px;
        option{
            background-color: white;
        }
    }
    &.datepicker{
        input{
            padding: 0 44px 0 15px;
            background: @bgColor01 url(/asset/images/icn-calandar-act.png) no-repeat right 15px center/14px;
            color: @pointColor;
        }
    }
    &.width_button{
        padding-right: 138px;
        position: relative;
        button, label{
            position: absolute;
            top: -1px;
            right: 0;
        }
    }
    &.homepage{
        input{
            color: @textColor01;
        }
    }
}
.file_upload_box{
    .afters;
    .input_box01{
        float: left;
        width: 100%;
        margin-bottom: 8px;
        &:last-child{
            margin-bottom: 0;
        }
        input[type="file"]{
            .bld;
        }
    }
    .button_box{
        float: left;
        .bld;
        width: 40px;
        height: 40px;
        position: relative;
        button{
            display: block;
            width: 20px;
            height: 20px;
            background-color: @pointColor;
            .bgo;
            background-size: 10px;
            font-size: 0;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            cursor: pointer;
            &.btn_refresh{
                background-image: url(/asset/images/icn-write-attachfile-refresh.png);
            }
            &.btn_delete{
                background-image: url(/asset/images/icn-write-attachfile-delete.png);
            }
        }
    }
    &.show{
        .input_box01{
            width: calc(100% - 40px);
        }
        .button_box{
            display: block;
        }
    }
}
.option_box{
    border: 1px solid @grey03;
    background-color: @bgColor01;
    padding: 0 10px;
    font-weight: 300;
    text-align: left;
    font-size: 15px;
    &:active,&:focus{
        border: @border-pointColor;
    }
    &.select_date{
        width: 100%;
        color: @pointColor;
        .eFont;
        padding: 0 34px 0 10px;
        background: @bgColor01 url(/asset/images/icn-calandar-act.png) no-repeat right 10px center/14px;
        height: 40px;
        &::placeholder{
           font-weight: 300;
           .fontA;
           color: inherit;
        }
    }
    &.select_options{
        .fontA;
        background: @bgColor01 url(/asset/images/object-arrow-down.png) no-repeat right 10px center/14px;
    }
    &.select_search{
        height: 40px;
        &::placeholder{
            color: @grey01;
        }
    }
}

/*buttons*/
.btn_read_more{
    padding: 3px 0;
    .inline-block;
    .inner{
        .inline-block;
        border-bottom: @border-pointColor;
        padding-bottom: 2px;
        span{
            .eFont;
            font-size: 13px;
            color: @pointColor;
            &::after{
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                .bgo;
                background-size: 100%;
                background-image: url(/asset/images/btn-readmore-linearrow.png);
                margin-left: 5px;
                vertical-align: -1px;
            }
        }
        
    }
}
// .btn_read_more_hover{
//     background-color: @pointColor;
//     .inner{
//         border-bottom: 1px solid white;
//         span{
//             color: white;
//         }
//     }
// }
.btn_map{
    padding: 4px 9px;
    background-color: @pointColor;
    border: @border-pointColor;
    span{
        font-size: 12px;
        font-weight: bold;
        color: white;
        &::after{
            content: '';
            .inline-block;
            width: 5px;
            height: 5px;
            .bgo;
            background-image: url(/asset/images/icn-btn-outlink.png);
            margin-left: 3px;
            vertical-align: 5px;
        }
    }
}
.btn_culture_event_view{
    .inline-block;
    padding: 4px 10px;
    border: @border-pointColor;
    span{
        font-size: 12px;
        font-weight: bold;
        color: @pointColor;
        &::after{
            content: '';
            .inline-block;
            width: 6px;
            height: 10px;
            margin-left: 5px;
            background: url(/asset/images/icn-arrow.png) no-repeat center/100%;
        }
    }
}
.btn_register{
    .inline-block;
    min-width: 168px;
    padding: 7px 26px 9px;
    background-color: @pointColor;
    span{
        font-weight: 500;
        letter-spacing: -.67px;
        color: white;
        &::before{
            content: '';
            .inline-block;
            width: 17px;
            height: 17px;
            background: url(/asset/images/icn-write.png) no-repeat center/100%;
            margin-right: 10px;
            vertical-align: -3px;
        }
    }
}
.btn_rg_request{
    .inline-block;
    padding: 6px 26px 8px;
    background-color: #fff;
    border: @border-pointColor;
    text-align: center;
    span{
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -.67px;
        color: @pointColor;
    }
}
.btn01{
    .inline-block;
    padding: 15px 0;
    width: 270px;
    background-color: @pointColor;
    text-align: center;
    cursor: pointer;
    span{
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;
    }
}
.btn02{
    width: 138px;
    padding: 10px 0;
    text-align: center;
    background-color: @pointColor;
    display: inline-block;
    cursor: pointer;
    span{
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
    }
}
.btn_file_add{
    .inline-block;
    padding: 5px 12px 6px;
    background-color: white;
    border: @border-pointColor;
    cursor: pointer;
    span{
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -.54px;
        color: @pointColor;
        &::before{
            content: '';
            .inline-block;
            width: 17px;
            height: 17px;
            background: url(/asset/images/icn-write-navy.png) no-repeat center/100%;
            margin-right: 5px;
            vertical-align: -4px;
        }
    }
}
.btn_download{
    .inline-block;
    padding: 8px 18px;
    background-color: @pointColor;
    span{
        font-weight: 500;
        letter-spacing: -.67px;
        color: white;
        &::before{
            content: '';
            .inline-block;
            width: 14px;
            height: 14px;
            background: url(/asset/images/icn-attachedfile-white.png) no-repeat center/11px;
            vertical-align: -1px;
            margin-right: 10px;
        }
    }
}
.btn_file_download{
    .inline-block;
    max-width: 100%;
    border-radius: 24px;
    background-color: @bgColor01;
    padding: 12px 20px;
    span{
        display: inline-block;
        max-width: 100%;
        font-size: 14px;
        font-weight: 300;
        .line-clamp-one;
        &::before{
            content: '';
            .inline-block;
            width: 13px;
            height: 13px;
            background: url(/asset/images/icn-attachedfile.png) no-repeat center/11px;
            margin-right: 10px;
            vertical-align: -2px;
        }
    }
}
.btn_top{
    position: fixed;
    right: 28px;
    bottom: 0;
    z-index: 10;
    padding-bottom: 28px;
    span{
        display: block;
        width: 40px;
        height: 40px;
        border: 1px solid @pointColor;
        background: white url(/asset/images/icn-w-top.png) no-repeat center/10px;
        transform: scale(0);
        transition: transform .2s ease-in;
        cursor: pointer;
    }
    &.show{
        span{
            transform: scale(1);
        }
    }
    &.sticky{
        position: absolute;
        bottom: auto;
        top: -(40px+28px);
    }
}
/*card_box*/
.card_box01{
    a{
        display: block;
        .afters;
        .img_box{
            .img{
                .bgo;
                background-color: lightgrey;
            }
        }
        figcaption{
            .group{
                font-size: 14px;
                font-weight: 500;
                color: @pointColor;
                margin-bottom: 6px;
                text-indent: 2px;
                .line-clamp-one;
            }
            .title,.subtitle{
                font-weight: 300;
                line-height: 1;
            }
            .subtitle{
                .line-clamp-one;
            }
            .desc{
                font-size: 13px;
                font-weight: 500;
                margin-bottom: 18px;
            }
            
        }
        &.ver1{
            .img_box{
                .img{
                    padding-top: 48.6%;
                }
            }
            figcaption{
                padding-top: 20px;
                .title,.subtitle{
                    // font-size: 38px;
                    font-size: 30px;
                    white-space: nowrap;
                }
                .title{
                    letter-spacing: -.2px;
                    .line-clamp-one;
                    margin-bottom: 10px;
                }
                .subtitle{
                    font-size: 22px;
                    margin-bottom: 20px;
                }
                .desc{
                    .line-clamp(1.69;2);
                }
            }
        }
        &.ver2{
            .left{
                &.img_box{
                    .img{
                        padding-top: 96.8%;
                    }
                }
            }
            figcaption{
                padding-top: 20px;
                .title,.subtitle{
                    letter-spacing: -.5px;
                }
                .title{
                    .line-clamp(1.17;2);
                    font-size: 24px;
                    margin-bottom: 8px;
                }
                .subtitle{
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                .desc{
                    .line-clamp(1.67;2);
                    font-size: 12px;
                }
            }
        }
        &.ver3{
            .left,.right{
                float: left;
            }
            .left{
                width: 30.7%;
                &.img_box{
                    .img{
                        padding-top: 127.8%;
                    }
                }
            }
            .right{
                width: 69.3%;
                padding-left: 4.6%;
            }
            figcaption{
                .title,.subtitle{
                    letter-spacing: -.39px;
                }
                .title{
                    .line-clamp(1.2;2);
                    font-size: 30px;
                    margin-bottom: 10px;
                }
                .subtitle{
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                .desc{
                    .line-clamp(1.71;2);
                }
            }
        }
    }
}
.card_box02{
    a{
        display: block;
        .img_box{
            position: relative;
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 140%;
            }
            .badge{
                display: block;
                min-width: 42px;
                text-align: center;
                font-size: 13px;
                color: #fff;
                font-weight: 500;
                letter-spacing: .09px;
                line-height: 1;
                padding: 3px 4px 4px;
                position: absolute;
                left: 0;
                top: 0;
            }
            .on_going_badge{
                width: 72px;
                height: 72px;
                position: absolute;
                right: 0;
                bottom: 0;
                &::before{
                    content: '';
                    width: 0px;
                    height: 0px;
                    border-top: 72px solid transparent;
                    border-right: 72px solid @pointColor;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
                span{
                    .eFont;
                    font-size: 15px;
                    line-height: .84;
                    color: #fff;
                    text-align: right;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
                }
            }
        }
        figcaption{
            padding-top: 10px;
            .title{
                font-size: 20px;
                font-weight: 300;
                .line-clamp(1.1;2);
                margin-bottom: 5px;
            }
            >ul{
                >li{
                    float: none;
                    display: inline;
                    word-break: keep-all;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.56;
                    letter-spacing: -.2px;
                    color: @textColor01;
                    margin-right: 5px;
                    &::after{
                        content: '';
                        .inline-block;
                        width: 1px;
                        height: 10px;
                        background-color: @grey01;
                        margin-left: 5px;
                        vertical-align: 1px;
                    }
                    &:last-child{
                        &::after{
                            .bld;
                        }
                    }    
                }
            }
            .location_box{
                .city{
                    &::before{
                        content: '';
                        .inline-block;
                        width: 11px;
                        height: 15px;
                        .bgo;
                        background-image: url(/asset/images/icn-ongoing-pin.png);
                        margin-right: 3px;
                        vertical-align: -3px;
                    }
                }
            }
        }
    }
}
.card_box03{
    a{
        display: block;
        .afters;
        .left,.right{
            float: left;
        }
        .left{
            width: 47%;
        }
        .right{
            width: 53%;
            padding-left: 5%;
        }
        .img_box{
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 96.6%;
            }
        }
        figcaption{
            .title,.subtitle{
                font-weight: 300;
                line-height: 1.4;
                letter-spacing: -.42px;
            }
            .title{
                font-size: 20px;
                margin-bottom: 10px;
                .line-clamp(1.4;3);
            }
            .subtitle{
                .line-clamp-one;
            }
        }

    }
}
.card_box04{
    a{
        display: block;
        .img_box{
            .img{
                padding-top: 134.6%;
                .bgo;
                background-color: lightgrey;
            }
        }
        figcaption{
            padding-top: 10px;
            text-align: center;
            .title{
                .eFont;
                font-size: 24px;
                line-height: 1;
                margin-bottom: 5px;
            }
            .subtitle{
                font-size: 18px;
                font-weight: 300;
                letter-spacing: -.37px;
            }
        }
    }
}
.card_box05{
    a{
        display: block;
        .img_box{
            .img{
                padding-top: 96.4%;
                .bgo;
                background-color: lightgrey;
            }
        }
        figcaption{
            padding-top: 10px;
            .title{
                font-size: 22px;
                font-weight: 300;
                margin-bottom: 5px;
                .line-clamp(1.27;2)
            }
            .subtitle{
                font-weight: 500;
                color: @textColor01;
                letter-spacing: -.3px;
                .line-clamp(1.31;2);
            }

        }
    }
}
.card_box06{
    a{
        display: block;
        .img_box{
            .img{
                .bgo;
                background-color: lightgrey;
                padding-top: 96.4%;
            }
        }
        figcaption{
            padding-top: 20px;
            .title{
                font-size: 22px;
                font-weight: 300;
                .line-clamp(1.27;2);
                margin-bottom: 10px;
            }
            .subtitle{
                font-weight: 500;
                color: @textColor01;
                letter-spacing: -.3px;
                .line-clamp(1.31;2);
            }
        }
    }
}
/*boxes*/
.box01{
    display: block;
    border-bottom: @border01;
    background-color: white;
    padding: 16px 12px 16px;
    .afters;
    .badge_box,.title,.date{
        float: left;
        padding-right: 10px;
    }
    .badge_box{
        .badge{
            .inline-block;
            padding: 1px 6px 2px;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.09px;
            color: white;
            margin-right: 5px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .title{
        width: calc(100% - 75px - 47px);
        font-size: 16px;
        font-weight: 300;
        letter-spacing: -.9px;
        .line-clamp-one;
    }
    .date{
        padding-right: 0;
        font-weight: 300;
        letter-spacing: -.9px;
    }
    &.impt{
        background-color: @bgColor01;
        .title{
            width: calc(100% - 75px - 112px);
        }
    }
}
.box02{
    display: inline-block;
    border: 1px solid @grey01;
    background-color: @bgColor01;
    padding: 20px 0;
    text-align: center;
    span{
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -.5px;
    }
}
.list_box02{
    display: table;
    width: 100%;
    table-layout: fixed;
    padding: 15px 0;
    border-bottom: @border01;
    .col{
        display: table-cell;
        vertical-align: middle;
    }
    .img_box{
        width: 110px;
        padding-left: 14px;
        padding-right: 18px;
        vertical-align: top;
        .img{
            width: 74px;
            padding-top: 100%;
            .bgo;
            background-color: lightgrey;
        }
    }
    .info_box{
        width: calc(100% - 110px - 120px);
        .kind{
            font-size: 15px;
            font-weight: 500;
            color: @pointColor;
            margin-bottom: 3px;
        }
        .title{
            font-size: 22px;
            font-weight: 500;
            .line-clamp(1.27;2);
            margin-bottom: 5px;
        }
        ul{
            li{
                font-size: 15px;
                font-weight: 300;
            }
        }
    }
    .date_box{
        width: 120px;
        padding-right: 14px;
        padding-left: 18px;
        .date{
            font-weight: 300;
        }
    }
}
.list_box03{
    .left{
        .title{
            .eFont;
            font-size: 18px;
            margin-bottom: 10px;
        }
    }
    .right{
        >p{
            letter-spacing: -.5px;
        }
        .img_box{
            margin-bottom: 10px;
            img{
                display: block;
            }
        }
        .color_box{
            width: 208px;
            border: 1px solid @pointColor;
            .title{
                padding: 3px 20px 5px;
                background-color: @pointColor;
                p{
                    .eFont;
                    font-size: 20px;
                    color: #fff;
                }
            }
            .cont{
                padding: 16px 20px 17px;
                background-color: #fff;
                p{
                    font-size: 13px;
                    font-weight: 500;
                    color: @pointColor;
                    line-height: 1.46;
                }
            }
        }
    }
}
.img_sld_box{
    display: block;
    height: 88px;
    img{
        width: auto;
        height: 100%;
    }
}
/*tabs*/
.tab01{
    text-align: center;
    .inner{
        border-bottom: 1px solid @grey02;
        >ul,.btn_read_more{
            vertical-align: middle
        }
        ul{
            .inline-block;
            li{
                a{
                    display: block;
                    font-size: 18px;
                    font-weight: 500;
                    color: @textColor03;
                    padding: 10px 0;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    box-shadow: 0 0 1px transparent;
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        z-index: 1;
                        left: 50%;
                        right: 50%;
                        bottom: -1px;
                        background: @pointColor;
                        height: 2px;
                        transition-property: left, right;
                        transition-duration: 0.2s;
                        transition-timing-function: ease-out;
                    }
                    &.on{
                        color: @pointColor !important;
                        &::before{
                            left: 0;
                            right: 0;
                        }
                    }
                }
            }
        }
        .btn_read_more{
            margin-left: 10px;
            .inner{
                span{
                    &::after{
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
.tab02{
    text-align: right;
    .inner{
        .inline-block;
        >ul,.btn_map{
            .inline-block;
            vertical-align: middle;
        }
        >ul{
            margin-right: 28px;
            li{
                margin-right: 8px;
                &::after{
                    content: '';
                    .inline-block;
                    width: 1px;
                    height: 8px;
                    background-color: @grey03;
                    margin-left: 8px;
                    vertical-align: 3px;
                }
                &:last-child{
                    margin-right: 0;
                    &::after{
                        .bld;
                    }
                }
                a{
                    font-size: 13px;
                    color: @grey01;
                    font-weight: bold;
                    &::before{
                        content: '';
                        .inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                        vertical-align: -3px;
                        .bgo;
                    }
                    &.list{
                        &::before{
                            background-image: url(/asset/images/icn-ongoing-list-dis.png);
                            background-size: 14px;
                        }
                    }
                    &.calender{
                        &::before{
                            background-image: url(/asset/images/icn-calandar-dis.png);
                            background-size: 14px;
                        }
                    }
                    &.on{
                        color: @pointColor;
                        &.list{
                            &::before{
                                background-image: url(/asset/images/icn-ongoing-list-act.png);
                            }
                        }
                        &.calender{
                            &::before{
                                background-image: url(/asset/images/icn-calandar-act.png);
                            }
                        }
                    }
                }
            }
        }
    }
}
/*panels*/
.panel01{
    .panel_tit{
        padding-bottom: 20px;
        text-align: center;
        p{
            .eFont;
            font-size: 40px;
            letter-spacing: 1.54px;
            color: @pointColor;
        }
    }
}
.panel02{
    .panel_tit{
        font-size: 22px;
        font-weight: bold;
        color: @pointColor;
        margin-bottom: 10px;
    }
    .panel_cont{
        p{
            font-size: 14px;
            font-weight: 500;
            line-height: 1.71;
            span{
                font-weight: inherit;
                line-height: inherit;
                font-size: inherit;
                &.strong{
                    font-weight: bold;
                }
                &.point{
                    font-weight: bold;
                    color: @pointColor;
                    &.eFont{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
.panel03{
    border: @border02;
    padding: 30px 0 40px;
    text-align: center;
    .panel_tit{
        .title{
            font-size: 20px;
            font-weight: bold;
            color: @pointColor;
            .num{
                font-size: 26px;
                .eFont;
                color: inherit;
            }
        }
        .subtitle{
            font-size: 14px;
            font-weight: 500;
            line-height: 1.71;
            margin-top: 5px;
        }
        .cmt{
            font-size: 11px;
            font-weight: 500;
            color: @textColor01;
        }
    }
    .panel_cont{
        padding-top: 24px;
    }
}
.panel04{
    .panel_tit{
        padding-bottom: 10px;
        text-align: center;
        .title{
            font-size: 22px;
            font-weight: bold;
            color: @pointColor;
        }
    }
    .panel_cont{
        border-top: @border-pointColor;
        border-bottom: @border-pointColor;
    }
}
.panel05{
    .panel_tit{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;     
    }
}
/*tables*/
.table01{
    border-top: @border-pointColor;
    border-bottom: @border-pointColor;
    .tr{
        display: block;
        border-top: 1px solid @grey02;
        padding: 0 6px;
        .afters;
        &:first-child{
            border-top: none;
        }
        &.thead{
            .bld;
        }
        .th,.td{
            float: left;
            text-align: center;
            letter-spacing: -.7px;
            padding: 15px 0;
        }
        .td{
            font-weight: 300;
            .badge_box{
                .badge{
                    display: inline-block;
                    padding: 1px 8px 2px;
                    font-size: 12px;
                    color: white;
                    min-width: 42px;
                    text-align: center;
                    margin-right: 5px;
                    &:last-child{
                        margin-right: 0;
                    }
                }
            }
            &:nth-child(2){
                text-align: left;
                .inner{
                    .afters;
                    >div{
                        float: left;
                    }
                }
                .badge_box{
                    padding-right: 8px;
                }
                .title{
                    font-weight: inherit;
                    max-width: calc(100% - 60px);
                    p{
                        padding-right: 18px;
                        position: relative;
                        .line-clamp-one;
                    }
                }
            }
            &.new{
                &:nth-child(2){
                    .title{
                        p{
                            &::after{
                                content: '';
                                .inline-block;
                                width: 13px;
                                height: 13px;
                                background: url(/asset/images/icn-new.png) no-repeat center/100%;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
        &.impt{
            background-color: @bgColor01;
            .td{
                &:nth-child(2){
                    .title{
                        max-width: calc(100% - 115px);
                    }
                }
            }
        }
    }
}
.table02{
    display: table;
    table-layout: fixed;
    width: 100%;
    border-top: @border-pointColor;
    border-bottom: @border-pointColor;
    .tr{
        display: table-row;
        &.thead{
            .bld;
        }
        &:nth-child(2){
            .td{
                border-top: none;
            }
        }
        .th,.td{
            display: table-cell;
            vertical-align: middle;
            background-color: #fff;
            text-align: center;
        }
        .td{
            padding: 14px 0;
            border-top: 1px solid @grey02;
            font-weight: 300;
            .badge_box{
                .badge{
                    display: inline-block;
                    padding: 1px 0 2px;
                    font-size: 12px;
                    color: white;
                    min-width: 42px;
                    text-align: center;
                }
            }
            &:nth-child(3){
                p{
                    font-weight: inherit;
                    text-align: left;
                    .line-clamp-one;
                }
            }
            &:last-child{
                position: relative;
            }
            .edit_buttons{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 86px;
                // .inline-block;
                li{
                    margin-right: 6px;
                    &:last-child{
                        margin-right: 0;
                    }
                    button{
                        font-size: 0;
                        width: 40px;
                        height: 40px;
                        .bgo;
                        background-color: @pointColor;
                        cursor: pointer;
                        &.btn_edit{
                            background-image: url(/asset/images/icn-pencil.png);
                            background-size: 19px;
                        }
                        &.btn_delete{
                            background-image: url(/asset/images/icn-del.png);
                            background-size: 13px;
                        }
                    }
                }
            }
            
        }
    }
}
.table03{
    table-layout: fixed;
    width: 100%;
    border-top: 1px solid @pointColor;
    th,td{
        padding: 15px 5px;
        border-bottom:  1px solid @grey01;
        font-size: 16px;
        letter-spacing: -.67px;
        text-align: center;
    }
    th{
        background-color: @bgColor01;
        font-weight: 500;
    }
    td{
        font-weight: 300;
        word-break: keep-all;
        a{
            font-weight: inherit;
            letter-spacing: inherit;
            font-size: inherit;
            text-decoration: underline;
            word-break: break-all;
        }
        &.tal{
            text-align: left;
        }
    }
    &.tdrow{
        td{
            padding: 15px 18px; 
        }
    }
}
/*lists*/
.list01{
    li{
        float: none;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 5px;
        position: relative;
        padding-left: 5px;
        word-break: keep-all;
        &:last-child{
            margin-bottom: 0;
        }
        &::before{
            content: '';
            display: block;
            width: 2px;
            height: 2px;
            background-color: @grey02;
            position: absolute;
            left: 0;
            top: 8px;
        }
    }
}
.list02{
    li{
        float: none;
        font-size: 15px;
        font-weight: 300;
        margin-bottom: 10px;
        position: relative;
        padding-left: 10px;
        word-break: keep-all;
        &:last-child{
            margin-bottom: 0;
        }
        &::before{
            content: '';
            display: block;
            width: 2px;
            height: 2px;
            background-color: @grey02;
            position: absolute;
            left: 0;
            top: 11px;
        }
    }
}
/*pagination*/
.paging_area{
    text-align: center;
    .pagination{
        .inline-block;
        >div{
            .inline-block;
            vertical-align: middle;
        }
        .prev,.next{
            .navigationBtnCommon
        }
        .prev{
            .navigationPrev;
        }
        .next{
            .navigationNext;
        }
        .num{
            margin: 0 46px;
            a{
                .inline-block;
                width: 14px;
                height: 14px;
                border: 1px solid white;
                border-radius: 50%;
                background-color: white;
                position: relative;
                margin-right: 6px;
                &:last-child{
                    margin-right: 0;
                }
                &:after{
                    content: '';
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: @pointColor;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                }
                &.on{
                    border: @border-pointColor;
                }
            }
        }
    }
    .pagination02{
        .inline-block;
        >div{
            .inline-block;
            vertical-align: middle;
        }
        .prev,.next,.prev_first,.next_last{
            .navigationBtnCommon
        }
        .prev{
            .navigationPrev;
        }
        .prev_first{
           .navigationPrevFirst;
            margin-right: 8px;
        }
        .next{
            .navigationNext;
            margin-right: 8px;
        }
        .next_last{
           .navigationNextLast;
        }
        .num{
            margin: 0 30px;
            a{
                .inline-block;
                margin-right: 15px;
                &:last-child{
                    margin-right: 0;
                }
                span{
                    .eFont;
                    font-size: 19px;
                    color: @grey02;
                }
                &.on{
                    span{
                        color: @pointColor;
                    }
                }
            }
        }
    }
}
/*header*/
header{
    background-color: #fff;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    .wrap01{
        position: relative;
    }
    .btn_ham{
        display: block;
        width: 22px;
        height: 16px;
        position: absolute;
        right: @padding;
        top: 22px;
        z-index: 20;
        cursor: pointer;
        
        &::before{
            content: '';
            display: block;
            width: 44px;
            height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: opacity .2s ease-in;
        }
        span{
            display: block;
            width: 100%;
            height: 2px;
            background-color: @pointColor;
            position: absolute;
            left: 0;
            transition: top .2s ease-in, transform .2s ease-in, background-color .2s ease-in;
            &:nth-child(1){
                top: 0;
            }
            &:nth-child(2){
                top: 45%;
            }
            &:nth-child(3){
                top: calc(100% - 2px);
            }
        }
        &.close{
            span{
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    width: 0;
                    // left: 20px;
                }

                &:last-child {
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }
        }
        &.close_ver1{
            .close;
            // &::before{
            //     background-color: @grey03;
            //     opacity: 1;
            // }
            span{
                background-color: #fff;
            }
        }
    }
    .btn_friends_close{
        visibility: hidden;
        width: 44px;
        height: 44px;
        position: absolute;
        right: @padding;
        top: 8px;
        z-index: 20;
        cursor: pointer;
        background-color: @pointColor;
        padding: 14px 11px;
        .inner{
            width: 22px;
            height: 16px;
            position: relative;
            span{
                display: block;
                width: 100%;
                height: 2px;
                background-color: white;
                position: absolute;
                left: 0;
                transition: top .3s ease-in, transform .3s ease-in;
                &:nth-child(1){
                    top: 0;
                }
                &:nth-child(2){
                    top: 45%;
                }
                &:nth-child(3){
                    top: calc(100% - 2px);
                }
            }
        }
       &.show{
            visibility: visible;
            span{
                &:first-child {
                    top: 50%;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    width: 0;
                    // left: 20px;
                }

                &:last-child {
                    top: 50%;
                    transform: rotate(-45deg);
                }
            }
       }
    }
    .top_gnb_area{
        .afters;
        padding: 16px 0;
        >div{
            float: right;
            >ul{
                display: inline-block;
                margin-right: 36px + 22px;
                li{
                    margin-right: 18px;
                    &:last-child{
                        margin-right: 0;
                    }
                    a{
                        font-size: 12px;
                        font-weight: bold;
                        letter-spacing: -.33px;
                        color: @pointColor;
                    }
                    &.lang{
                        >a{
                            color: @grey01;
                            &::after{
                                content: ' I ';
                                color: @grey01;
                            }
                            &:last-child{
                                &::after{
                                    .bld;
                                }
                            }
                            &.on{
                                color: @pointColor;
                            }
                        }
                    }
                    &.login_after{
                        .bld !important;
                    }
                }
            }
        }
        &.login{
            >div{
                >ul{
                    li{
                        &.login_before{
                            .bld !important;
                        }
                        &.login_after{
                            display: block !important;
                        }
                    }
                }
            }
        }
    }
    .main_gnb_area{
        font-size: 0;
        padding-bottom: 24px - 5px;
        nav{
            display: inline-block;
            vertical-align: text-bottom;
            ul{
                li{
                    float: none;
                    display: inline-block;
                    font-size: 0;
                    vertical-align: text-bottom;
                    // margin-right: 20px;
                    margin-right: 10px;
                    margin-bottom: 5px;
                    &:last-child{
                        margin-right: 0;
                    }
                    &:first-child{
                        margin-right: 10px;
                    }
                    a{
                        display: block;
                        // font-size: 39px;
                        font-size: 43px;
                        font-weight: 500;
                        letter-spacing: -.98px;
                        color: @pointColor;
                        text-transform: capitalize;
                        &.on{
                            color: #fff;
                            background-color: @pointColor;
                        }
                    }
                    .logo{
                        a{
                            display: block;
                            // width: 120px;
                            // height: 132px;
                            width: 140px;
                            height: 152px;
                            img{
                                display: block;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .search_area{
        border-top: @border-pointColor;
        border-bottom: @border-pointColor;
        padding: 6px 0;
        .inner{
            position: relative;
            padding: 0 6px;
            .ggc{
                width: 0;
                background-color: @pointColor;
                position: absolute;
                top: 0;
                left: 0;
                transition: width .3s ease-in;
                transition-delay: .2s;
                overflow: hidden;
                span{
                    position: relative;
                    top: -5px;
                    font-size: 40px;
                    .eFont;
                    color: white;
                    line-height: 1;
                    letter-spacing: 4px;
                }
            }
            form{
                .afters;
                input[type="text"],.btn_search{
                    float: left;
                }
                input[type="text"]{
                    width: calc(100% - 40px);
                    height: 40px;
                    text-align: right;
                    padding: 0 20px 0 (106px+10px);
                    font-size: 24px;
                    font-weight: 500;
                    color: @pointColor;
                    &:focus{
                        outline: none;
                    }
                }
                .btn_search{
                    padding: 0; //ios
                    margin: 0; //ios
                    cursor: pointer;
                    span{
                        width: 40px;
                        height: 40px;
                        display: block;
                        background: @pointColor url(/asset/images/icn-zoom.png) no-repeat center/24px;
                    }
                }
            }
        }
        &.sticky{
            .inner{
                .ggc{
                    width: 106px;
                }
            }
        }
    }
    .friends{
        .bld;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 544px;
        background-color: white;
        z-index: 10;
        outline: @border-pointColor;
        padding-top: 20px;
        .check_option_box{
            width: calc(100% - (44px + 30px));
            text-align: right;
            padding: 5px 0;
            border-bottom: 1px solid @grey02;
        }
        .friends_list_box{
            padding-top: 20px;
            .keyword{
                .eFont;
                font-size: 40px;
                color: @pointColor;
            }
            >a{
                display: inline-block;
               font-size: 21px;
               font-weight: bold;
               color: @textColor01;
               letter-spacing: -.6px;
               margin-right: 10px;
               word-break: keep-all;
            }
        }

    }
    .site_map{
        .bld;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: #0e4278;
        z-index: 10;
        .wrap01{
            padding: 0;
            .group1{
                padding: 48px @padding 40px;
                background-color: #093766;
                a{
                    .inline-block;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-right: 0;
                    }
                    span{
                        font-size: 34px;
                        font-weight: 300;
                        letter-spacing: 1px;
                        line-height: 1;
                        color: white;
                        text-transform: capitalize;
                    }
                    &.impt{
                        span{
                            // font-weight: bold;
                        }
                    }
                    
                }
            }
            .group2{
                padding: 50px @padding 44px;
                background-color: #003c71;
                ul{
                    width: 100%;
                    li{
                        width: (100% - 10%*2)/3;
                        margin-bottom: 30px;
                        margin-right: 10%;
                        height: 63px;
                        &:nth-child(3n){
                            margin-right: 0; 
                        }
                        a{
                            .inline-block;
                            .title{
                                .inline-block;
                                .eFont;
                                font-size: 30px;
                                color: white;
                                margin-bottom: 5px;
                            }
                            .subtitle{
                                font-size: 13px;
                                font-weight: 500;
                                color: #a5d0fd;
                            }
                        }
                    }
                }
            }
            .group3{
                padding: 40px @padding;
                background-color: #0e4278;
                dl{
                    float: none;
                    margin-bottom: 20px;
                    .afters;
                    &:last-of-type{
                        margin-bottom: 0;
                    }
                    dt,dd{
                        float: left;
                    }
                    dt{
                        margin-right: 20px;
                        a{
                            font-size: 32px;
                            font-weight: 300;
                            letter-spacing: .67px;
                            color: white;
                            line-height: 1;
                            position: relative;
                        }
                    }
                    dd{
                        margin-top: 10px;
                        ul{
                            li{
                                margin-right: 5px;
                                &::after{
                                    content: '';
                                    .inline-block;
                                    width: 2px;
                                    height: 2px;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    margin-left: 5px;
                                    vertical-align: 5px;
                                }
                                &:last-child{
                                    margin-right: 0;
                                    &::after{
                                        .bld;
                                    }
                                }
                                a{
                                    width: 14px;
                                    font-weight: 500;
                                    color: white;
                                    letter-spacing: -.39px;
                                    position: relative;
                                }
                            }
                        }   
                    }
                }
            }
        }
    }
    &.sticky{
        position: fixed;
        .main_gnb_area{
            .bld;
        }
        .search_area{
            .search_area.sticky;
        }
    }
}
/*footer*/
footer{
    border-top: @border01;
    padding: 40px 0;
    position: relative;
    .g1{
        &.left{
            float: left;
            width: 22.2%;
            .ft_logo{
                a{
                    display: block;
                    margin-bottom: 10px;
                    width: 100px;
                    &:nth-child(1){
                        height: 43px;
                    }
                    &:nth-child(2){
                        height: 60px;
                        pointer-events: none;
                    }
                    img{
                        display: block;
                        margin: 0 auto;
                        height: 100%;
                    }
                }
            }
        }
        &.right{
            float: right;
            width: 77.8%;
        }
    }
    .g2{
        &.left{
            margin-bottom: 17px;
            text-align: center;
            // .company{
            //     font-size: 15px;
            //     font-weight: bold;
            //     color: @pointColor;
            //     margin-bottom: 5px;
            //     a{
            //         display: inline-block;
            //         border-bottom: 1px solid #a8a8a8;
            //         margin-left: 12px;
            //         font-size: 0;
            //         vertical-align: 3px;
            //         span{
            //             font-size: 9px;
            //             letter-spacing: -.54px;
            //             color: #a8a8a8;
            //             &::after{
            //                 content: '';
            //                 display: inline-block;
            //                 width: 4px;
            //                 height: 4px;
            //                 background: url(/asset/images/icn-btn-outlink-gray.png) no-repeat center/100%;
            //                 margin-left: 2px;
            //                 vertical-align: 3px;
            //             }
            //         }
            //     }
            // }
            // address{
            //     font-style: normal;
            //     color: @textColor01;
            //     font-size: 14px;
            //     font-weight: 500;
            //     word-break: keep-all;
            //     margin-bottom: 8px;
            //     a{
            //         font-size: inherit;
            //         font-weight: inherit;
            //         color: inherit;
            //         pointer-events: none;
            //     }
            // }
            // .copyrights{
            //     .eFont;
            //     font-size: 15px;
            //     color: @textColor01;
            // }
            address{
                max-width: 560px;
                font-style: normal;
                font-size: 14px;
                font-weight: 500;
                line-height: 1.57;
                color: @pointColor;
                margin: 0 auto 10px;
                a,span{
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                    color: inherit;
                    white-space: nowrap;
                }
            }
            .ft_list{
                display: inline-block;
                li{
                    margin-right: 8px;
                    &::after{
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 8px;
                        background-color: #e8e8e8;
                        margin-left: 8px;
                    }
                    &:last-child{
                        &::after{
                            .bld;
                        }
                    }
                    a{
                        font-size: 12px;
                        font-weight: bold;
                        color: @textColor03;
                    }
                }
            }
        }
        &.right{
            width: 210px;
            margin: 0 auto;
        }
    }

}
/*popup*/
.layer_popup{
    .bld;   
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.86);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    .popup_contents{
        max-width: calc(100% - @padding*2);
        width: 100%;
        position: absolute;
        left: 50%;
        top: 100px;
        transform: translateX(-50%);
        background-color: #fff;
        .btn_close{
            width: 44px;
            height: 44px;
            background: @grey03 url(/asset/images/icn-menu-close-navy.png) no-repeat center/18px;
            cursor: pointer; 
            position: absolute;
            right: 0;
            top: 0;
        }
        .title_box{
            padding: 41px 0;
            text-align: center;
            background: @pointColor url(/asset/images/img-ggc-popup-bg.png) no-repeat center/100%;
            p{
                font-size: 26px;
                font-weight: bold;
                color: white;
                word-break: keep-all;
            }
        }
        .contents_box{
            padding: 34px @padding 55px;
        }
    }
    &.signup_notice{
        .contents_box{
            .desc{
                margin-bottom: 35px;
                p{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.71;
                }
            }
            .panel03{
                margin-bottom: 25px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            .benefits{
                .panel_cont{
                    font-size: 0;
                    padding-left: 10%;
                    padding-right: 10%;
                    ul{
                        width:100%; 
                        li{
                            width: 25%;
                            &:nth-child(1){
                                .icon{
                                    background-image: url(/asset/images/pic-join-01.png);
                                }
                            }
                            &:nth-child(2){
                                .icon{
                                    background-image: url(/asset/images/pic-join-02.png);
                                }
                            }
                            &:nth-child(3){
                                .icon{
                                    background-image: url(/asset/images/pic-join-03.png);
                                }
                            }
                            &:nth-child(4){
                                .icon{
                                    background-image: url(/asset/images/pic-join-04.png);
                                }
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            .icon{
                                .inline-block;
                                width: 62px;
                                height: 62px;
                                .bgo;
                                background-size: 100%;
                            }
                            .text{
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 1.42;
                                color: @pointColor;
                            }
                        }
                    }
                }
            }
            .application_form_guide{
                .panel_cont{
                    text-align: left;
                    padding-left: 40px;
                    padding-right: 40px;
                    dl{
                        float: none;
                        margin-bottom: 10px;
                        &.first-child{
                            dt{
                                margin-top: 13px;
                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                        .afters;
                        dt,dd{
                            float: left;
                            font-size: 13px;
                        }
                        dt{
                            width: 90px;
                            font-weight: 500;
                            margin-top: 2px;
                        }
                        dd{
                            width: calc(100% - 90px);
                            font-weight: 300;
                            ul{
                                li{
                                    float: none;
                                    font-size: 13px;
                                    font-weight: 300;
                                    padding-left: 5px;
                                    position: relative;
                                    margin-bottom: 5px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    &::before{
                                        content: '';
                                        display: block;
                                        width: 2px;
                                        height: 2px;
                                        background-color: @grey02;
                                        position: absolute;
                                        left: 0;
                                        top: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .closing_ment{
                margin-top: 30px;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
                .point{
                    color: @pointColor;
                    font-size: inherit;
                    font-weight: inherit;
                }
            }
        }
    }
    &.contents_request{
        .contents_box{
            .panel03{
                margin-bottom: 24px;
                &:last-child{
                    margin-bottom: 32px;
                }
                .panel_tit{
                    .title{
                        font-size: 22px;
                    }
                }
                &.form_download{
                    .panel_cont{
                        padding-left: 6.5%;
                        padding-right: 6.5%;
                        .category_list{
                            .inline-block;
                            >li{
                                width: 100%/3;
                                padding: 25px 28px;
                                text-align: center;
                                position: relative;
                                &::after{
                                    content: '';
                                    display: block;
                                    width: 1px;
                                    height: 135px;
                                    background-color: @grey03;
                                    position: absolute;
                                    right: 0;
                                    top: 25px;
    
                                }
                                &::before{
                                    content:'';
                                    display: block;
                                    width: calc(100% - 28px*2);
                                    height: 1px;
                                    background-color: @grey03;
                                    position: absolute;
                                    left: 28px;
                                    top: 0;
                                }
                                &:nth-child(3n){
                                    &::after{
                                        .bld;
                                    }
                                }
                                &:nth-child(-n+3){
                                    &::before{
                                        .bld;
                                    }
                                }
                                .category{
                                    font-weight: 500;
                                    color: @pointColor;
                                    margin-bottom: 10px;
                                }
                                .icon{
                                    .inline-block;
                                    width: 50px;
                                    height: 50px;
                                    margin-bottom: 5px;
                                    .bgo;
                                    &.display{
                                        background-image: url(/asset/images/pic-request-01.png);
                                        background-size: 48px;
                                    }
                                    &.play{
                                        background-image: url(/asset/images/pic-request-02.png);
                                        background-size: 35px;
                                    }
                                    &.event{
                                        background-image: url(/asset/images/pic-request-03.png);
                                        background-size: 42px;
                                    }
                                    &.education{
                                        background-image: url(/asset/images/pic-request-04.png);
                                        background-size: 50px;
                                    }
                                    &.news{
                                        background-image: url(/asset/images/pic-request-05.png);
                                        background-size: 36px;
                                    }
                                    &.modification_request{
                                        background-image: url(/asset/images/pic-request-06.png);
                                        background-size: 40px;
                                    }
                                }
                                .download_list{
                                    >li{
                                        float: none;
                                        margin-bottom: 5px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        a{
                                            display: block;
                                            font-size: 12px;
                                            font-weight: 500;
                                            letter-spacing: -.5px;
                                            text-decoration: underline;
                                            &::before{
                                                content: '';
                                                display: inline-block;
                                                width: 18px;
                                                height: 18px;
                                                vertical-align: -4px;
                                                margin-right: 5px;
                                                .bgo;
                                                background-size: 100%;
                                                
                                            }
                                            &.hwp{
                                                &::before{
                                                    background-image: url(/asset/images/icn-download-hangul.png);
                                                }
                                            }
                                            &.docx{
                                                &::before{
                                                    background-image: url(/asset/images/icn-download-msword.png);
                                                }
                                            }
                                        }
                                    }
                                }
                                 
                            }
                        }
                    }
                }
                &.form_upload{
                    .panel_cont{
                        padding-left: 8.5%;
                        padding-right: 8.5%;
                        dl{
                            float: none;
                            margin-bottom: 15px;
                            .afters;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            dt,dd{
                                float: left;
                            }
                            dt {
                                width: 100px;
                                font-size: 13px;
                                font-weight: 500;
                                margin-top: 10px;
                                text-align: left;
                            }
                            dd{
                                width: calc(100% - 100px);
                                .file_upload_box{
                                    &.show{
                                        .input_box01{
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .btn_area{
                            padding-top: 25px;
                            text-align: center;
                        }
                    }
                }
                
            }
            .precaution_box{
                background-color: @bgColor01;
                padding: 30px 40px;
                .title{
                    font-size: 13px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }
            }
        }
    }
    &.privacy_policy{
        .contents_box{
            max-height: 600px;
            overflow-y: auto;
            h2{
                font-size: 18px;
                font-weight: bold;
                color: @pointColor;
                margin-bottom: 10px;
                span{
                    font-size: 14px;
                    font-weight: 500;
                    color: inherit;
                }
            }
            >.desc{
                font-size: 14px;
                font-weight: 500;
                line-height: 1.75;
                margin-bottom: 30px;
                word-break: keep-all;
            }
            .g1{
                margin-bottom: 30px;
                h3{
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.38;
                    margin-bottom: 15px;
                }
                >.desc{
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1.57;
                    margin-bottom: 10px;
                    word-break: keep-all;
                }
            }
        }   
    }
}
/*tour*/
.tour_box{
    background-color: @pointColor;
    position: relative;
    &::after{
        content: '';
        width: 100%;
        height: 88px+48px;
        background-color: #fff;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .sc_title{
        padding-top: 20px;
        h2,p{
            color: white !important;
        }
    }
    .sc_cont{
        padding-top: 20px;
        position: relative;
        z-index: 1;
        .tour_search_box{
            width: 87.6%;
            background-color: @grey03;
            padding: 32px 24px 0;
            margin: 0 auto;
            text-align: center;
            &::after{
                content: '';
                width: 100%;
                height: 24px;
                background-color: #fff;
                position: absolute;
                left: 0;
                bottom: 0;
            }
            dl{
                width: calc((100% - 50px)/2);
                margin-right: 50px;
                .afters;
                &:last-child{
                    margin-right: 0;
                }
                dt,dd{
                    float: left;
                }
                dt{
                    width: 70px;
                    font-weight: bold;
                    color: @textColor01;
                    padding-right: 10px;
                    line-height: 48px;
                }
                dd{
                    width: calc(100% - 70px);
                }
            }
            .tour_input{
                width: 100%;
                height: 48px;
                color: @pointColor;
                .eFont;
                font-size: 22px;
                padding: 0 44px 0 15px;
                background: white url(/asset/images/icn-calandar-act.png) no-repeat right 15px center/14px;
                &::placeholder{
                    font-size: 20px;
                    font-weight: 300;
                    .fontA;
                    color: inherit;
                }
            }
            .location,.date{
                .afters;
            }
            .location{
                margin-bottom: 16px;
            }
            .date{
                margin-bottom: 32px;
                dl{
                    position: relative;
                    &:first-child{
                        &::before{
                            content: '';
                            display: block;
                            width: 26px;
                            height: 4px;
                            .bgo;
                            background-image: url(/asset/images/icn-w-linearrow-ggctour-navy.png);
                            position: absolute;
                            right: -(26px + 12px);
                            bottom: 20px;
                        }
                    }
                }
            }
            .btn_tour_search{
                width: 210px;
                text-align: center;
                padding: 10px 0;
                background-color: @pointColor;
                cursor: pointer;
                position: relative;
                z-index: 1;
                span{
                    .eFont;
                    font-size: 20px;
                    color: #fff;
                    margin-right: 5px;
                    &::before{
                        content: '';
                        .inline-block;
                        width: 24px;
                        height: 24px;
                        .bgo;
                        background-image: url(/asset/images/zoom-white.png);
                        background-size: 24px;
                        margin-right: 8px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
.tour_box02{
    .title{
        padding: 7px 0 9px;
        text-align: center;
        background-color: @pointColor;
        p{
            .eFont;
            font-size: 24px;
            color: white;
            line-height: 1;
            .subtitle{
                font-weight: bold;
                font-size: 14px;
                letter-spacing: -.44px;
                color: inherit;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }
    .tour_search_box{
        padding: 15px 0;
        border-bottom: @border01;
        .afters;
        .location,.date,.btn_tour_search{
            float: left;
        }
        .location{
            width: 22.8%;
            margin-right: 2%;
        }
        .date{
            width: 49.2%;
            margin-right: 2%;
            .afters;
            >.input_box01{
                width: calc((100% - 42px)/2);
                float: left;
            }
            .start_date{
                margin-right: 42px;
                position: relative;
                &::after{
                    content: '';
                    display: block;
                    width: 26px;
                    height: 4px;
                    .bgo;
                    background-image: url(/asset/images/icn-w-linearrow-ggctour-navy.png);
                    position: absolute;
                    right: -(26px + 10px);
                    bottom: 20px;
                }
            }
        }
        .btn_tour_search{
            .tour_box > .sc_cont > .tour_search_box > .btn_tour_search;
            width: 24%;
            padding: 7px 0 8px;
            span{
                font-size: 18px;
                &::before{
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }
}

/*no_result*/
.no_result_case{
    margin: 16px 0 117px;
    .guide_box{
        max-width: 576px;
        margin: 0 auto;
        border: @border02;
        padding: 46px 36px 46px 45px;
        .afters;
        .left,.right{
            float: left;
        }
        .left{
            width: 23.2%;
            .icon{
                width: 88px;
                height: 96px;
                .bgo;
                background-image: url(/asset/images/img-search-nodata.png);
            }
        }
        .right{
            width: 76.8%;
            .g_title{
                font-size: 20px;
                font-weight: 500;
                line-height: 1.6;
                word-break: keep-all;
                margin-bottom: 10px;
                .word{
                    font-size: inherit;
                    font-weight: bold;
                    line-height: inherit;
                    color: @pointColor;
                    &::before{
                        content: '‘';
                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                    }
                    &::after{
                        content: '’';
                        font-size: inherit;
                        font-weight: inherit;
                        color: inherit;
                    }
                }
            }
            .list01{
                li{
                    font-size: 13px;
                    &::before{
                        top: 10px;
                    }
                }
            }
        }
    }
}
/*-------------------------------------------------------------------
    Pages
-------------------------------------------------------------------*/
#contentsWrap{
    padding-top: 300px;//기본값
    opacity: 0;
    &.main_wrap{
        padding-bottom: 40px;
        section{
            margin-bottom: @mainSectionBottom;
            &:last-child{
                margin-bottom: 0;
            }
            .sc_title{
                text-align: center;
                h2{
                    .eFont;
                    font-size: 44px;
                    color: @pointColor;
                    margin-bottom: 5px;
                }
                .subtitle{
                    font-size: 18px;
                    font-weight: 300;
                    color: @pointColor;
                }
            }
        }
        .articles{
            padding-top: 32px;
            .row{
                .afters;
                margin-bottom: 32px;
                &:last-child{
                    margin-bottom: 0;
                }
                >div{
                    margin-right: 3.9%;
                    &:last-child{
                        margin-right: 0;
                    }
                }
                .col7{
                    width: 100%;
                    margin-bottom: 32px;
                }
                .col3{
                    .afters;
                    .card_box01{
                        float: left;
                        .list-width-single-row(3.9%; 3);
                    }
                }
                .col5{
                    width: 100%;
                    margin-bottom: 24px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        .news{
            .sc_cont{
                padding-top: 28px;
                .tab01{
                    .inner{
                        ul{
                            width: calc(100% - 106px);
                            li{
                                width: 25%;
                            }
                        }
                    }
                }
                .tab01_cont{
                    padding-top: 32px;
                    >div{
                        border-top: @border01;
                    }
                }
            }
        }
        .on_going{
            .sc_cont{
                padding-top: 5px;
                .tab02{
                    .inner{
                        >ul{
                            .bld;
                        }
                    }
                }
                .tab02_cont{
                    padding-top: 20px;
                    >div{
                        .swiper-slide{
                            .list-width-single-row(3%; 3);
                        }
                    }
                }
            }
        }
        .ggc_guide{
            .sc_cont{
                padding-top: 30px;
                .keyword_box{
                    border: @border01;
                    margin-bottom: 40px;
                    padding: 23px;
                    position: relative;
                    .question{
                        max-width: calc(100% - 90px);
                        font-size: 32px;
                        font-weight: 300;
                        letter-spacing: -.64px;
                        line-height: 1.3;
                        color: @pointColor;
                        .typing_box{
                            display: inline-block;
                            position: relative;
                            font-size: inherit;
                            letter-spacing: inherit;
                            line-height: inherit;
                            padding-right: 3px;
                            >span{
                                font-size: inherit;
                                font-weight: 500;
                                color: white;
                                letter-spacing: inherit;
                                line-height: inherit;
                            }
                            .typing_keyword{
                                opacity: 0;
                            }
                            .keyword{
                                background-color: @pointColor;
                                position: absolute;
                                left: 0;
                                top: 0;
                                &.on{
                                    display: inline-block; 
                                    animation-name: cursor; 
                                    animation-duration: 0.3s; 
                                    animation-iteration-count: infinite; 
                                }
                            }
                        }
                        
                    }
                    .btn_read_more{
                        position: absolute;
                        top: 50%;
                        right: 23px;
                        transform: translateY(-50%);
                    }
                }
                .swiper-container{
                    padding-bottom: 80px - 24px;
                    .swiper-slide{
                        padding-right: 1.2%;
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                        .card_box03{
                            margin-bottom: 24px;
                        }
                    }
                }
            }
        }
        .ggc_book{
            .sc_cont{
                padding-top: 25px;
                .sld_box_area{
                    margin: 0 auto;
                    position: relative;
                    .swiper-container{
                        width: 86%;
                        padding-bottom: 0;
                        .swiper-slide{
                            width: 100%/3;
                            padding: 0 12px;
                        }    
                    }
                    .sld_prev,.sld_next{
                        position: absolute;
                        top: 32%;
                    }
                    .sld_prev{
                        left: 0;    
                    }
                    .sld_next{
                        right: 0;
                    }
                }
            }
        }
        .ggc_issue{
            // .sc_title{
            //     .keyword_list{
            //         width: 300px;
            //         margin: 10px auto 0;
            //         border-top: @border01;
            //         border-bottom: @border01;
            //         position: relative;
            //         padding: 4px 0;
            //         .swiper-container{
            //             padding-bottom: 0;
            //             .swiper-slide{
            //                 .keyword{
            //                     font-size: 18px;
            //                     font-weight: 300;
            //                     color: @pointColor;
            //                 }
            //             }
            //         }
            //         .btn_sld_prev,.btn_sld_next{
            //             position: absolute;
            //             top: 0;
            //             width: 24px;
            //             height: 100%;
            //             .bgo;
            //             background-size: 8px;
            //             background-color: #fff;
            //             z-index: 1;
            //             cursor: pointer;
            //         }
            //         .btn_sld_prev{
            //             left: 0;
            //             .navigationPrev;
            //         }
            //         .btn_sld_next{
            //             right: 0;
            //             .navigationNext;
            //         }
            //     }
            // }
            .sc_cont{
                .keyword_list{
                    width: 300px;
                    margin: 10px auto 0;
                    border-top: @border01;
                    border-bottom: @border01;
                    position: relative;
                    padding: 4px 0;
                    text-align: center;
                    .keyword{
                        font-size: 18px;
                        font-weight: 300;
                        color: @pointColor;
                    }
                }
                // .sld_box_area{
                //     
                //     .afters;
                //     .card_box03{
                //         float: left;
                //         .list-width-multi-row(2.8%; 2);
                //         margin-bottom: 24px;
                //     }
                //     .paging_area{
                //         padding-top: 40px - 24px;
                //     }
                // }
                // .swiper-container{
                //     padding-bottom: 80px - 24px;
                //     .swiper-slide{
                //         padding-right: 1.2%;
                //         &:nth-child(3n){
                //             margin-right: 0;
                //         }
                //         .card_box03{
                //             margin-bottom: 24px;
                //         }
                //     }
                // }
                .swiper-container{
                    padding-bottom: 80px - 24px;
                    .swiper-slide{
                        .sld_section{
                            padding-top: 32px;
                            .afters;
                            .card_box03{
                                margin-bottom: 24px;
                                float: left;
                                .list-width-multi-row(1.2%; 2);
                            }
                        }
                    }
                }
            }
        }
        .inside_gyeonggido{
            margin-bottom: @mainSectionBottom - 20px;
            .sc_cont{
                padding-top: 26px;
                font-size: 0;
                .box02{
                    .list-width-multi-row(1%; 10);
                    margin-bottom: 10px;
                }
            }
        }
        .whats_new{
            .sc_cont{
                padding-top: 26px;
                display: flex;
                flex-wrap: wrap;
                .card_box05{
                    .list-width-multi-row(@listGap01; 3);
                    margin-bottom: 28px;
                }
            }
        }
        
    }
    &.sub_wrap{
        padding-bottom: 70px;
        .title_section{
            padding: 50px 0 44px;
            text-align: center;
            h2{
                font-size: 32px;
                font-weight: 500;
                letter-spacing: -.8px;
                color: @pointColor;
                line-height: 1;
                text-transform: capitalize;
            }
        }
        .tab01{
            .inner{
                ul{
                    li{
                        a{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        .options_box{
            margin-bottom: 20px;
            .total_list{
                margin-bottom: 10px;
                p{
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: -.58px;
                    >span{
                        .inline-block;
                        font-size: inherit;
                        font-weight: inherit;
                        letter-spacing: inherit;
                        color: @grey02;
                        margin-left: 5px;
                    }
                }
            }
            .options_select{
                display: flex;
                ul{
                    li{
                        margin-right: 8px;
                    }
                }
                .select_search_box{
                    display: flex;
                    width: 270px+40px;
                    font-size: 0;
                    .select_search{
                        width: 270px;
                    }
                    .btn_search{
                        // .inline-block;
                        vertical-align: middle;
                        width: 40px;
                        height: 40px;
                        background: @pointColor url(/asset/images/icn-zoom.png) no-repeat center/22px;
                        vertical-align: -13px;
                    }
                }
            }
        }
        .paging_area{
            margin-top: 40px;
        }
        .btn_area{
            padding-top: 30px;
            font-size: 0;
            text-align: center;
        }
        &.post_list{
            .sld_list_section{
                margin-top: 40px;
                margin-bottom: 90px;
            }
            .archive_list_section{
                position: relative;
                .target_point{
                    display: block;
                    width: 1px;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    top: -200px;
                }
                .options_box{
                    .afters;
                    margin-bottom: 24px;
                    ul{
                        float: right;
                        li{
                            width: 160px;
                            margin-right: 10px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
                .list_cont{
                    display: flex;
                    flex-wrap: wrap;
                    .card_box06{
                        .list-width-multi-row(@listGap01; 3);
                        margin-bottom: 28px;
                    }
                    
                    
                }
            }
        }
        &.post_list01{
            .sld_list_section{
                .tab01{
                    .inner{
                        width: 528px;
                        margin: 0 auto;
                        ul{
                            width: 100%;
                            li{
                                width: 50%;
                            }
                        }
                    }
                }
                .tab01_cont{
                    padding-top: 26px;
                    position: relative;
                    .top_btn_area{
                        text-align: right;
                        margin-bottom: 16px;
                    }
                    .swiper-container{
                        .swiper-wrapper{
                            .swiper-slide{
                                .list-width-single-row(@listGap01; 3);
                            }
                        }
                    }
                    .btn_register{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        z-index: 1;
                    }
                }
            }
            .archive_list_section{
                .list_title{
                    text-align: center;
                    margin-bottom: 25px;
                    h3{
                        font-size: 32px;
                        font-weight: 500;
                        letter-spacing: -.8px;
                        color: @pointColor;
                        line-height: 1;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 18px;
                        font-weight: 300;
                        color: @pointColor;
                    }
                }
            }
        }
        &.post_list02{
            .sld_list_section{
                .swiper-container{
                    .swiper-wrapper{
                        .swiper-slide{
                            .card_box06{
                                .img_box{
                                    .img{
                                        padding-top: 48.6%;
                                    }
                                }
                                figcaption{
                                    .title{
                                        font-size: 30px;
                                        letter-spacing: -.39px;
                                        .line-clamp(1.2; 2);
                                    }
                                    .subtitle{
                                        font-size: 20px;
                                        font-weight: 300;
                                        letter-spacing: -.42px;
                                        .line-clamp(1.2; 2);
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &.tour{
                .tour_box02{
                    margin-bottom: 22px;
                }
            }
        }
        &.post_view{
            h3{
                font-size: 32px;
                font-weight: bold;
                color: @pointColor;
                letter-spacing: -.8px;
                margin-bottom: 24px;
            }
            h4{
                .eFont;
                font-size: 22px;
                letter-spacing: -.34px;
                color: @pointColor;
                margin-bottom: 15px;
            }
            .sns_box{
                text-align: center;
                ul{
                    .inline-block;
                    li{
                        margin-right: 10px;
                        &:last-child{
                            margin-right: 0;
                        }
                        a{
                            display: block;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: @pointColor;
                            font-size: 0;
                            text-align: center;
                            .bgo;
                            &.twitter{
                                background-image: url(/asset/images/icn-article-sharetwt.png);
                                background-size: 21px;
                            }
                            &.facebook{
                                background-image: url(/asset/images/icn-article-sharefb.png);
                                background-size: 11px;
                            }
                            &.print{
                                background-image: url(/asset/images/icn-article-print.png);
                                background-size: 20px;
                            }
                            &.like{
                                background-image: url(/asset/images/icn-article-like-default.png);
                                background-position: top 10px center;
                                background-size: 14px;
                                position: relative;
                                span{
                                    font-size: 12px;
                                    color: #fff;
                                    .eFont;
                                    position: absolute;
                                    display: block;
                                    width: 100%;
                                    bottom: 4px;
                                }
                                &.on{
                                    background-image: url(/asset/images/icn-article-like-act.png);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.post_view01{
            .post_view_area{
                .view_top{
                    border-top: @border-pointColor;
                    padding: 50px 0;
                    .title_box{
                        margin-bottom: 26px;
                        text-align: center;
                        .badge_box{
                            margin-bottom: 10px;
                            .badge{
                                display: inline-block;
                                padding: 3px 19px 5px;
                                min-width: 78px;
                                font-weight: 500;
                                color: #fff;
                                vertical-align: middle;
                                margin-right: 10px;
                                &:last-child{
                                    margin-right: 0;
                                }
                                &.ongoing{
                                    padding: 5px 19px 7px;
                                }
                            }
                        }
                        .post_title{
                            font-size: 32px;
                            font-weight: 300;
                            margin-bottom: 10px;
                            word-break: keep-all;
                        }
                        .post_date{
                            font-size: 20px;
                            font-weight: 300;
                        }
                    }
                    .img_box{
                        margin-bottom: 32px;
                        overflow: hidden;
                        img{
                            display: block;
                            margin: 0 auto;
                            height: 358px;
                        }
                    }
                }
                .view_bottom{
                    .info_box,.map_box{
                        border-top: @border01;
                    }
                    .info_box{
                        padding: 45px 0;
                        .cont{
                            .left,.right{
                                dl{
                                    float: none;
                                    margin-bottom: 10px;
                                    .afters;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    dt,dd{
                                        float: left;
                                    }
                                    dt{
                                        width: 68px;
                                        font-size: 13px;
                                        font-weight: bold;
                                    }
                                    dd{
                                        width: calc(100% - 68px);
                                        font-size: 13px;
                                        font-weight: 300;
                                        word-break: keep-all;
                                        a{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .map_box{
                        padding: 60px 0 45px;
                        text-align: center;
                        .map{
                            position: relative;
                            padding-top: 57.6%;
                            margin-bottom: 10px;
                            iframe{
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                        .address{
                            .inline-block;
                            float: none;
                            .afters;
                            dt,dd{
                                float: left;
                                font-size: 13px;
                                font-weight: bold;
                            }
                        }
                        
                    }
                }
            }
            .gallery_area{
                padding: 20px 0 30px;
                background-color: @bgColor01;
                text-align: center;
                .sld_box_area{
                    position: relative;
                    .swiper-container{
                        padding-bottom: 0;
                        .swiper-wrapper{
                            .swiper-slide{
                                width: auto;
                                margin-right: 12px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                    .sld_next,.sld_prev{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%); 
                    }
                    .sld_prev{
                        left: -(40px + 24px);
                    }
                    .sld_next{
                        right: -(40px + 24px);
                    }
                }
                
            }
            .recommend_area{
                padding-top: 56px;
                text-align: center;
                .swiper-container{
                    .swiper-wrapper{
                        .swiper-slide{
                            .list-width-single-row(@listGap01; 3);
                        }
                    }
                }
            }
        }
        &.post_view02{
            .post_view_area{
                padding-top: 32px;
                border-bottom: @border01;
                margin-bottom: 54px;
                .wrap02{
                    padding: 0;
                }
                .post_top{
                    .img_box{
                        margin-bottom: 30px;
                        .img{
                            padding-top: 48.5%;
                            .bgo;
                            background-color: lightgrey;
                        }
                    }
                    .title_box{
                        margin-bottom: 36px;
                        text-align: center;
                        >p{
                            line-height: 1;
                        }
                        .group{
                            font-size: 16px;
                            font-weight: 500;
                            color: @pointColor;
                            margin-bottom: 10px;
                        }
                        .title{
                            font-size: 32px;
                            font-weight: 300;
                            line-height: 1.25;
                            word-break: keep-all;
                            margin-bottom: 15px;
                        }
                        .subtitle{
                            font-size: 20px;
                            font-weight: 300;
                        }
                    }
                }
                .post_mid{
                    border-top: @border01;
                    .post_box{
                        padding: 50px 0 60px;
                        .post_view_txt_section;
                        .copyright_phrase{
                            margin-top: 30px;
                            font-size: 13px;
                            font-weight: 500;
                            color: #4a4a4a;
                            text-align: center;
                        }
                    }
                    .info_box{
                        border-top: @border01;
                        padding: 40px 0 50px;
                        .row{
                            margin-bottom: 20px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                            h4{
                                margin-bottom: 10px;
                            }
                            .cont{
                                font-size: 0;
                                >dl{
                                    float: none;
                                    margin-bottom: 5px;
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                    .afters;
                                    dt,dd{
                                        float: left;
                                        font-size: 13px;
                                    }
                                    dt{
                                        width: 66px;
                                        font-weight: bold;
                                        margin-bottom: 5px;
                                    }
                                    dd{
                                        width: calc(100% - 66px);
                                        font-weight: 300;
                                        line-height: 1.57;
                                        >a{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            line-height: inherit;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                                >ul{
                                    li{
                                        float: none;
                                        margin-bottom: 5px;
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                        .ui-sortable-handle{
                                            display: flex;
                                            font-size: 13px;
                                            font-weight: 300;
                                            line-height: 1.57;
                                            margin-bottom: 5px;
                                            &:last-child{
                                                margin-bottom: 0;
                                            }
                                            span{
                                                display: block;
                                                font-size: 13px;
                                                font-weight: bold;
                                                white-space: nowrap;
                                                margin-right: 5px;
                                            }
                                            >a{
                                                font-size: inherit;
                                                font-weight: inherit;
                                                line-height: inherit;
                                            }
                                        }
                                    }
                                }
                                .pebble_box{
                                    .inline-block;
                                    padding: 3px 7px;
                                    border-radius: 12px;
                                    background-color: #e8e8e8;
                                    margin-right: 5px;
                                    margin-bottom: 5px;
                                    &:last-child{
                                        margin-right: 0;
                                    }
                                    span{
                                        font-size: 12px;
                                        font-weight: 500;
                                        &::before{
                                            font-size: inherit;
                                            font-weight: inherit;
                                            display: inline;
                                        }
                                    }
                                }
                                .hashtag{
                                    .pebble_box{
                                        span{
                                            &::before{
                                                content: '#';
                                            }
                                        }
                                    }
                                }
                                .at{
                                    .pebble_box{
                                        span{
                                            &::before{
                                                content: '@';
                                            }
                                        }
                                    }
                                }
                            }
                            &.sns_box{
                                text-align: left;
                            }
                        }
                    }
                }
                .post_bot{
                    border-top: @border01;
                    padding: 24px 0;
                    .afters;
                    .left,.right{
                        float: left;
                    }
                    .img_box{
                        width: 144px;
                        padding: 10px;
                        border: 1px solid @grey02;
                        .img{
                            width: 100%;
                            padding-top: 100%;
                            .bgo;
                            background-color: lightgrey;
                        }
                    }
                    .right{
                        width: calc(100% - 144px);
                        padding-left: 30px;
                        dl{
                            float: none;
                            margin-bottom: 5px;
                            .afters;
                            dt,dd{
                                float: left;
                                font-size: 14px;
                            }
                            dt{
                                font-weight: bold;
                                color: @pointColor;
                                margin-right: 5px;
                                &::after{
                                    content: '/';
                                    font-weight: inherit;
                                    color: inherit;
                                }
    
                            }
                            dd{
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
            .recommend_area{
                .row{
                    margin-bottom: 40px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                    h3{
                        text-align: center;
                    }
                    .sld_box_area{
                        .afters;
                        .card_box06{
                            float: left;
                            .list-width-single-row(@listGap01;3);
                            &:nth-child(3n){
                                margin-right: 0;
                            }
                            &:last-child{
                                .bld;
                            }
                        }
                    }
                }
            }
        }
        &.search_result{
            .wrap02{
                padding-top: 56px;
            }
            .panel01{
                margin-bottom: 48px;
                &:last-child{
                    margin-bottom: 0;
                }
                .panel_cont{
                    .list_boxes{
                        border-top: @border-pointColor;
                        border-bottom: @border-pointColor;
                        padding: 10px 0;
                        margin-bottom: 30px;
                        .list_box02{
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                    
                }
            }
        }
        &.culture_events{
            .tab01{
                .inner{
                    width: 74%;
                    margin: 0 auto;
                    ul{
                        width: 100%;
                        li{
                            width: 100% /5;
                        }
                    }
                }
            }
            .tab_cont{
                padding-top: 30px;
                .options_box{
                    .options_select{
                        ul{
                            width: calc(100% - 310px);
                            li{
                                width: calc((100% - 8px*3)/3);
                            }
                        }
                    }
                }
                .lists_box{
                    margin-bottom: 5px;
                    .afters;
                    .card_box02{
                        float: left;
                        .list-width-multi-row(@listGap01; 3);
                        margin-bottom: 30px;

                    }
                }
            }
        }
        &.news_list{
            .tab01{
                .inner{
                    width: 74%;
                    margin: 0 auto;
                    ul{
                        width: 100%;
                        li{
                            width: 100% /5;
                        }
                    }
                }
            }
            .tab_cont{
                .tab_title_box{
                    padding: 30px 0 34px;
                    text-align: center;
                    h3{
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 1;
                    }
                }
                .options_box{
                    .options_select{
                        ul{
                            width: 100% - 60%;
                            li{
                                width: calc((100% - 8px*2)/2);
                            }
                        }
                        .select_search_box{
                            width: 60%;
                            .select_search{
                                width: calc(100% - 40px);
                            }
                        }
                    }
                }
                .lists_box{
                    .table01{
                        .tr{
                            .th,.td{
                                &:nth-child(1){
                                    .bld;
                                }
                                &:nth-child(2){
                                    width: calc(100% - 100px);
                                }
                                &:nth-child(3){
                                    .bld;
                                }
                                &:nth-child(4){
                                    width: 100px;
                                }
                                &:nth-child(5){
                                    .bld;
                                }
                            }
                        }
                    }
                }
                .paging_area{
                    position: relative;
                    .btn_register{
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
            }
        }
        &.news_view{
            .post_view_area{
                border-top: @border-pointColor;
                border-bottom: @border-pointColor;
                .title_box{
                    padding: 40px 0 46px;
                    border-bottom: @border01;
                    text-align: center;
                    .title_list{
                        &::after{
                            content: '';
                            display: block;
                            width: 20px;
                            height: 1px;
                            background-color: #333;
                            margin: 20px auto;
                        }
                        .badge_box{
                            margin-bottom: 5px;
                            .badge{
                                .inline-block;
                                padding: 4px 5px;
                                min-width: 42px;
                                font-size: 12px;
                                color: #fff;
                                line-height: 1;
                                text-align: center;
                                margin-right: 8px;
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                        }
                        .post_title{
                            width: 87.5%;
                            margin: 0 auto;
                            font-size: 32px;
                            font-weight: 300;
                            line-height: 1.25;
                            word-break: keep-all;
                            margin-bottom: 5px;
                        }
                        .group{
                            font-size: 20px;
                            font-weight: 300;
                        }   
                    }
                    .info_list{
                        .inline-block;
                        li{
                            margin-right: 30px;
                            position: relative;
                            &::after{
                                content: '';
                                display: block;
                                width: 1px;
                                height: 8px;
                                background-color: #e8e8e8;
                                position: absolute;
                                right: -14px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                            &:last-child{
                                margin-right: 0;
                                &::after{
                                    .bld;
                                }
                            }
                            dl{
                                float: none;
                                .afters;
                                dt,dd{
                                    float: left;
                                }
                                dt{
                                    font-size: 15px;
                                    font-weight: 500;
                                    letter-spacing: 2px;
                                    padding-right: 10px;
                                    margin-top: 2px;
                                }
                                dd{
                                    font-weight: 300;
                                }
                                &.period{
                                    dd{
                                        color: @pointColor;
                                        font-weight: 500;
                                    }
                                }
                            }

                        }
                    }

                }
                .post_view_box{
                    padding: 40px 0 50px;
                    .post_box{
                        .post_view_txt_section;
                    }
                    .cf_file_box{
                        border: 2px solid @grey03;
                        margin-top: 30px;
                        .afters;
                        .left,.right{
                            float: left;
                        }
                        .title{
                            padding: 22px 20px;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        .download_list{
                            padding: 16px 16px 16px 0; 
                            width: calc(100% - 96px);
                            .btn_file_download{
                                margin-bottom: 8px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.article{
            .search_box01{
                width: 74%;
                border: @border02;
                padding: 24px 0;
                text-align: center;
                margin: 0 auto 35px;
                >div{
                    .inline-block;
                    .options_box>.options_select;
                    ul{
                        li{
                            width: 126px;
                        }
                    }
                }
            }
            .tab01{
                .inner{
                    width: 74%;
                    margin: 0 auto;
                    ul{
                        width: 100%;
                        li{
                            width: 100% /6;
                        }
                    }
                }
            }
            .tab_cont{
                padding-top: 56px;
                .options_box{
                    margin-bottom: 15px;
                    .afters;
                    .left{
                        float: left;
                    }
                    .right{
                        float: right;
                    }
                    .total_list{
                        margin-bottom: 0;
                        margin-top: 20px;
                    }
                }
                .lists_box{
                    .table02{
                        .th,.td{
                            &:nth-child(1){
                               .bld;
                            }
                            &:nth-child(2){
                                width: 62px;
                            }
                            &:nth-child(3){
                                width: calc(100% - (62px + 100px + 98px));
                            }
                            &:nth-child(4){
                                width: 100px;
                            }
                            &:nth-child(5){
                                .bld;
                            }
                            &:nth-child(6){
                                width: 98px;
                            }
                        }
                    }
                }
            }
        }
        &.write_form{
            .tab01{
                .inner{
                    width: 74%;
                    margin: 0 auto;
                    ul{
                        width: 100%;
                        li{
                            width: 100% /5;
                        }
                    }
                }
            }
            .tab_cont{
                padding-top: 30px;
                .form_box{
                    padding: 15px 0 50px;
                    .row{
                        margin-bottom: 20px;
                        .afters;
                        .col{
                            float: left;
                            width: (100% - 3.3%)/2;
                            margin-right: 3.3%;
                            &:nth-child(even){
                                margin-right: 0;
                            }
                        }
                        dl{
                            float: none;
                            dt{
                                margin-bottom: 5px;
                                position: relative;
                                >span{
                                    display: inline-block;
                                    font-weight: 500;
                                    font-size: 14px;
                                    color: #3a3a3a;
                                }
                                &.ls_wide{
                                    >span{
                                        letter-spacing: 3.73px;
                                    }
                                }
                                .cmt{
                                    font-size: 11px;
                                    font-weight: bold;
                                    color: @pointColor;
                                    letter-spacing: normal;
                                    margin-left: 5px;
                                }
                                .required_cmt{
                                    font-size: 12px;
                                    font-weight: 500;
                                    color: @textColor04;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    &::before{
                                        content: '*';
                                        font-weight: 500;
                                        color: @red02;
                                    }
                                }
                            }
                            dd{
                                margin-bottom: 10px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                &.period{
                                    .afters;
                                    .input_box01{
                                        float: left;
                                        position: relative;
                                        width: calc((100% - 25px) / 2);
                                    }
                                    .start{
                                        margin-right: 24px;
                                        &::after{
                                            content: '';
                                            display: block;
                                            width: 8px;
                                            height: 1px;
                                            background-color: @grey02;
                                            position: absolute;
                                            right: -16px;
                                            top: 19px;
                                        }
                                    }
                                }
                                &.multi{
                                    .afters;
                                    >.input_box01{
                                        float: left;
                                        width: calc((100% - 11px)/2);
                                        &:first-child{
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                            &.required{
                                dt{
                                    &::after{
                                        content: '*';
                                        font-size: 14px;
                                        font-weight: 500;
                                        color: @red02;
                                    }
                                }
                            }
                        }
                    }
                    .agreement_box{
                        border: @border02;
                        padding: 21px 38px;
                        .all_check_box{
                            margin-bottom: 10px;
                            .check_box02{
                                text-indent: -4px;
                            }
                        }
                        .check_boxes{
                            font-size: 0;
                        }
                    }
                    .precaution_box{
                        border: @border02;
                        padding: 30px;
                        .title{
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }
                    }  
                }
            }
        }
        &.introduce{
            .title_section{
                margin-top: 32px;
                padding: 56px 0;
                background: #07294e url(/asset/images/img-ggc-intro-bg.jpg) no-repeat center/ auto 100%;
                h2{
                    color: white;
                }
            }
            .contents_section{
                border-top: @border-pointColor;
                padding-top: 46px;
                .panel02{
                    margin-bottom: 35px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .ggc_introduce{
                    .panel_cont{
                        p{
                            margin-bottom: 10px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .ggc_service{
                    .panel_cont{
                        padding-top: 10px;
                        .afters;
                        .left,.right{
                            float: left;
                            .img_box{
                                img{
                                    display: block;
                                    width: 100%;
                                }
                            }
                        }
                        .left{
                            width: (100% - 4.7%)*0.572%;
                            margin-right: 4.7%;
                        }
                        .right{
                            width: (100% - 4.7%)*0.428%;
                        }
                    }
                }
                .ggc_logo{
                    .panel_cont{
                        padding-top: 10px;
                        .list_box03{
                            margin-bottom: 26px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                           .right{
                               font-size: 0;
                               .btn_download{
                                   margin-right: 16px;
                                   &:last-child{
                                       margin-right: 0;
                                   }
                               }
                           } 
                        }    
                    }
                } 
            }
        }
        &.open_api{
            .wrap06{
                padding: 0 @padding; 
            }
            .tab01{
                .inner{
                    width: 70%;
                    margin: 0 auto;
                    ul{
                        width: 100%;
                        li{
                            width: 50%;
                        }
                    }
                }
            }
            .tab_title_box02{
                padding: 30px 0 34px;
                text-align: center;
                h3{
                    font-size: 22px;
                    font-weight: bold;
                    color: @pointColor;
                    margin-bottom: 8px;
                }
                .subtitle{
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.71;
                    color: @textColor04;
                }
            }
            .btn_area{
                display: flex;
                justify-content: flex-end;
                padding-top: 0;
            }
            .panel05{
                margin-bottom: 24px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*-------------------------------------------------------------------
    Lang : en
-------------------------------------------------------------------*/
.en{
    header{
        .top_gnb_area{
            >div{
                >ul{
                    margin-right: 0;
                }
            }
        }
        .main_gnb_area{
            nav{
                ul{
                    li{
                        a{
                            .eFont;
                        }
                    }
                }
            }
        }
        .site_map{
            .wrap01{
                .group1{
                    a{
                        span{
                            .eFont;
                            font-weight: 400;
                            font-size: 31px;
                        }
                        &.impt{
                            span{
                                font-weight: 900;
                            }
                        }
                    }
                }
                .group3{
                    dl{
                        dt{
                            a{
                                .eFont;
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
    .card_box01{
        a{
            figcaption{
                .subtitle{
                    line-height: 1.5;
                    margin-bottom: 10px;
                }
            }
            &.ver1{
                figcaption{
                    .group{
                        margin-bottom: 0;
                    }
                    .title{
                        line-height: 1.5;
                        margin-bottom: 5px;        
                    }
                }
            }
        }
    }
    .card_box03{
        a{
            figcaption{
                .title{
                    .line-clamp(1.4; 4);
                    margin-bottom: 5px;
                }
                .subtitle{
                    white-space: normal;
                    .line-clamp(1.43; 2)
                }
            }
        }
    }
    .panel02{
        .panel_tit{
            .eFont;
        }
    }
    #contentsWrap{
        &.sub_wrap{
            .title_section{
                h2{
                    .eFont;
                }
            }
            &.introduce{
                .title_section{
                    h2{
                        text-transform: none;
                    }
                }
                .contents_section{
                    .ggc_introduce{
                        .panel_cont{
                            p{
                                margin-bottom: 0;
                                &.bold{
                                    font-size: 19px;
                                    font-weight: bold;
                                    span{
                                        &.eFont{
                                            font-size: 26px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ggc_service{
                        .panel_cont{
                            .col{
                                margin-bottom: 15px;
                                .afters;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                .left,.right{
                                    float: left;
                                    height: 192px;
                                    display: flex;
                                    justify-content: center;
                                    flex-direction: column;
                                }
                                .left{
                                    width: 24%;
                                    margin-right: 0;
                                    padding-right: 5%;
                                    img{
                                        display: block;
                                        width: 100%;
                                    }
                                }
                                .right{
                                    width: 76%;
                                }
                            }
                        }
                    }
                }   
            }
        }
    }
}

/*-------------------------------------------------------------------
    Keyframes
-------------------------------------------------------------------*/
@keyframes cursor{ 
    0%{border-right: 2px solid #fff} 
    50%{border-right: 2px solid @pointColor} 
    100%{border-right: 2px solid #fff} 
}